import cs from 'classnames';
import { ReactNode } from 'react';

export type Props = {
  className?: string;
  noPadding?: boolean;
  children?: ReactNode;
};

export const ContainerCard = ({ noPadding, className, children }: Props) => {
  return (
    <div
      className={cs(
        className,
        'rounded-xl border border-primary bg-primary shadow-lg',
        { 'py-6 px-6 sm:py-10 sm:px-10': !noPadding },
      )}
    >
      {children}
    </div>
  );
};
